import React, { useEffect, useState } from 'react';
import {
  Tag, Tooltip, Modal,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { Bar } from 'react-chartjs-2';
import { parse, stringify } from 'query-string';
import {
  Legend,
  ResponsiveContainer,
  BarChart as Barchartt,
  Bar as Barr,
  XAxis,
  YAxis,
  Tooltip as Tooltipp,
} from 'recharts';
import Loader from '../../../common/components/Loader';
import BarChart from '../../../common/components/BarChart';
import './stack.scss'
import ShowStudiesData from '../ShowStudiesData';
import ScoreModal from '../ScoreModal';
import { getPurpleLabDataForSiteExperienceAction } from './logic'

const CenterDetails = () => {
  const [useDeepDiveData, setdeepDiveData] = useState([]);
  const [phaseVisible, setphaseVisible] = useState(false);
  const [sponsorVisible, setSponsorVisible] = useState(false);
  const [patientTrendVisible, setPatientTrendVisible] = useState(false);
  const [ageRangeVisible, setAgeRangeVisible] = useState(false);
  const [statusVisible, setstatusVisible] = useState(false);
  const [indicationVisible, setindicationVisible] = useState(false);
  const deepDiveData = useSelector((state) => state.deepDive);
  const [onTierClicked, setOnTierClicked] = useState(false)
  const [transformedData, setTransformedData] = useState([])
  const [totalPatient, setTotalPatient] = useState('')
  const [isConfirmationModal, setIsConfirmationModal] = useState(false)
  const dispatch = useDispatch()

  const getPurpleLabDataForSiteExperienceResponse = useSelector((redux) => redux.getPurpleLabDataForSiteExperienceResponse || {})

  useEffect(() => {
    if (deepDiveData.flag) {
      setdeepDiveData(deepDiveData.data);
      if (deepDiveData.data.status_bar_graph.length) {
        const data = deepDiveData.data.status_bar_graph.map(({ key, pxl_studies, industry_studies }) => ({ key, 'Pxl Studies': pxl_studies, 'Industry Studies': industry_studies }));
        setTransformedData(data)
      }
    }
  }, [JSON.stringify(deepDiveData)]);

  const onParexelContact = (otherPersonnel) => {
    const data = otherPersonnel.filter((itm) => itm.role === 'Parexel Contact').map((itm1) => itm1.full_name)
    return data.length ? data.join(', ') : '-'
  }

  const onTrialCongestion = (value) => {
    if (value === 'High') {
      return <span className="high-value">High</span>
    }
    if (value === 'Low') {
      return <span className="low-value">Low</span>
    }
    if (value === 'Average') {
      return <span className="medium-value">Average</span>
    }
    return ''
  }

  useEffect(() => {
    dispatch(getPurpleLabDataForSiteExperienceAction({
      hcotype: 'Experience',
      id: parse(location.search).id,
    }))
  }, [])

  useEffect(() => {
    if (getPurpleLabDataForSiteExperienceResponse.flag) {
      setTotalPatient(getPurpleLabDataForSiteExperienceResponse.data.data.total_patient)
    }
  }, [JSON.stringify(getPurpleLabDataForSiteExperienceResponse)])

  const tags = [
    { name: 'Tier', size: useDeepDiveData.tier },
    { name: 'Total PIs', size: useDeepDiveData.total_number_of_popis },
    { name: 'Parexel # studies', size: useDeepDiveData.parexel_studies },
    { name: 'Alliance type', size: useDeepDiveData.alliance_type ? useDeepDiveData.alliance_type : '-' },
    { name: 'Center Type', size: useDeepDiveData.center_type ? useDeepDiveData.center_type : '-' },
    {
      name: 'Indications',
      size:
        useDeepDiveData.indications && useDeepDiveData.indications.length
          ? useDeepDiveData.indications.join('; ')
          : '-',
    },
    {
      name: 'Sources',
      size:
        useDeepDiveData.sources && useDeepDiveData.sources.length
          ? useDeepDiveData.sources.join(', ')
          : '-',
    },
    { name: 'Last onsite visit date', size: useDeepDiveData.last_onsite_visit_date ? useDeepDiveData.last_onsite_visit_date : '-' },
    { name: 'Start-Up Index', size: useDeepDiveData?.startup_rating ? useDeepDiveData.startup_rating : '-' },
    { name: 'Start-Up Factor', size: useDeepDiveData?.startup_factor_average ? useDeepDiveData.startup_factor_average.toFixed(2) : '-' },
    { name: 'Average Start-up Months', size: useDeepDiveData?.startup_average ? useDeepDiveData.startup_average.toFixed(2) : '-' },
    { name: 'SIMS IDs', size: useDeepDiveData?.pxl_center_sims_ids?.length ? useDeepDiveData.pxl_center_sims_ids.join(', ') : '-' },
    { name: 'Citeline IDs', size: useDeepDiveData?.pxl_center_cit_ids?.length ? useDeepDiveData.pxl_center_cit_ids.join(', ') : '-' },
    { name: 'OMS org IDs', size: useDeepDiveData?.oms_org_id ? useDeepDiveData.oms_org_id : '-' },
    { name: 'OMS loc IDs', size: useDeepDiveData?.oms_loc_id ? useDeepDiveData.oms_loc_id : '-' },
    { name: 'LandSCAPE ID', size: useDeepDiveData?.landscape_id ? useDeepDiveData.landscape_id : '-' },
    { name: 'Department', size: useDeepDiveData?.department ? useDeepDiveData.department : '-' },
    { name: 'Parexel Contact', size: useDeepDiveData?.other_personnel?.length ? onParexelContact(useDeepDiveData?.other_personnel) : '-' },
    { name: 'Total Patients', size: totalPatient || 0 },
    { name: 'Relative Trial Congestion', size: useDeepDiveData?.relative_trial_congestion_avg ? onTrialCongestion(useDeepDiveData?.relative_trial_congestion_avg) : '-' },
    { name: 'Center Quality', size: useDeepDiveData?.center_quality ? useDeepDiveData?.center_quality : '-' },
    { name: 'Overall Enrollment Percentile', size: '-' },
    { name: 'Veeva Ready Status', size: useDeepDiveData?.veeva_ready_flag_updated ? useDeepDiveData?.veeva_ready_flag_updated : '-' },
    { name: 'External Id', size: useDeepDiveData?.external_id ? useDeepDiveData?.external_id : '-' },
  ];

  const showGraph = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  const showPhaseModal = () => {
    setphaseVisible(true);
  };

  const showSponsorModal = () => {
    setSponsorVisible(true);
  };

  const showPatientTrendModal = () => {
    setPatientTrendVisible(true);
  };

  const showAgeRangeModal = () => {
    setAgeRangeVisible(true);
  };

  const showStatusModal = () => {
    setstatusVisible(true);
  };

  const showIndicationModal = () => {
    setindicationVisible(true);
  };

  const handlePhaseCancel = (e) => {
    e.stopPropagation();
    setphaseVisible(false);
  };
  const handleSponsorCancel = (e) => {
    e.stopPropagation();
    setSponsorVisible(false);
  };
  const handlePatientTrendCancel = (e) => {
    e.stopPropagation();
    setPatientTrendVisible(false);
  };
  const handleAgeRangeCancel = (e) => {
    e.stopPropagation();
    setAgeRangeVisible(false);
  };
  const handleStatusCancel = (e) => {
    e.stopPropagation();
    setstatusVisible(false);
  };

  const handleIndicationCancel = (e) => {
    e.stopPropagation();
    setindicationVisible(false);
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const renderGraph = (record) => {
    const combineData = []
    const label = []
    const hispanic = record?.census_data?.hispanic;
    hispanic.forEach((r) => {
      const k = r.key
      if (k !== 'Total') {
        const fData = record?.census_data.non_hispanic?.filter((f) => f.key !== 'Total').find((i) => i.key === k)
        label.push(
          r.key,
        )
        combineData.push({
          key: k,
          hdata: fData.value,
          nondata: r.value,
        });
      }
    })
    return {
      labels: label,
      datasets: [
        {
          label: 'Hispanic',
          data: combineData.map((r) => r.nondata),
          backgroundColor: '#D3D800',
          stack: 'Stack 0',
        },
        {
          label: 'Non-Hispanic',
          data: combineData.map((r) => r.hdata),
          backgroundColor: '#4E565B',
          stack: 'Stack 1',
        },
      ],
    }
  }

  const [showStudiesModal, setStudiesModal] = useState(false)
  const [clickedData, setClickedData] = useState(false)

  const handleCancel = () => {
    setStudiesModal(false);
  };

  const onBarsClick = (e) => {
    setClickedData(e)
    setStudiesModal(true)
  }

  const onTierClick = () => {
    setOnTierClicked(true)
  }
  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }
  return (
    <div className="CardList">
      <div className="search-result-list">
        <Loader loading={deepDiveData.loading || getPurpleLabDataForSiteExperienceResponse.loading} error={deepDiveData.error}>
          <div className="card">
            <div className="content deepdive-content">
              <div className="right-side">
                <div style={{ display: 'flex' }}>
                  <div style={{ color: '#848484' }}> Parent Institution Name: </div>
                  {useDeepDiveData?.alliance_member && useDeepDiveData?.alliance_member.length ? (
                    <a
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        ...parse(location.search),
                        id: useDeepDiveData?.alliance_member_id,
                        currentTab: 'parent_centers',
                      })}`}
                      rel="noopener noreferrer"
                    >&nbsp;{useDeepDiveData?.alliance_member}
                    </a>
                  ) : useDeepDiveData.solo_center_flag === true ? (
                    <span className="value" style={{ fontSize: '14px' }}>&nbsp;Solo Center / No Parent</span>
                  ) : <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>}
                </div>
                <div className="tags-data-content">
                  {tags.map((it, idx) => (
                    <Tag key={idx}>
                      <div className="details-abt-tags">
                        {it.name === 'Indications' ? (
                          <>
                            <div className="nameOfTag">{it.name}</div>
                            <Tooltip placement="bottom" title={it.size}>
                              {useDeepDiveData.indications
                                && useDeepDiveData.indications.length > 1 ? (
                                  <span className="sizeOftag">
                                    {it.size}
                                  </span>
                                ) : (
                                  <span className="sizeOftag">{it.size}</span>
                                )}
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <div className="nameOfTag">{it.name}</div>
                            <Tooltip placement="bottom" title={it.size}>
                              {it.name === 'Tier' ? <div role="presentation" onClick={onTierClick} className="sizeOftag tier-wrap">{it.size}</div> : <div className="sizeOftag">{it.size}</div>}
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </Tag>
                  ))}
                </div>

                {onTierClicked && <div><ScoreModal onItmClicked={onTierClicked} setOnItmClicked={setOnTierClicked} data={useDeepDiveData} flag="site-tier" /></div>}

                <div className="reliability-quality-rating">
                  <Tooltip title={useDeepDiveData.reliability ? `Reliability score : ${useDeepDiveData.reliability}` : '-'}>
                    <div className="reliability rating-sec">
                      <div className="rating-sec-title">Reliability : </div>
                      {useDeepDiveData.reliability_class ? (
                        <StarRatings
                          rating={getStars(useDeepDiveData.reliability_class)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </Tooltip>
                  <div className="quality rating-sec">
                    <div className="rating-sec-title"> Quality : </div>
                    {useDeepDiveData.quality ? (
                      <StarRatings
                        rating={useDeepDiveData.quality}
                        starRatedColor="#8A0050"
                        numberOfStars={3}
                        starDimension="16px"
                        starSpacing="1px"
                        starEmptyColor="#D099B9"
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="leftside">
                <div className="person-details">
                  <div className="address-recent">
                    <div className="title-child">Address:</div>
                    {useDeepDiveData?.address ? (
                      <Tooltip
                        placement="bottom"
                        title={(
                          <div className="content-child">
                            {useDeepDiveData.address?.address_line1 && `${useDeepDiveData.address?.address_line1}, `}
                            {useDeepDiveData.address?.address_line2 && `${useDeepDiveData.address?.address_line2}, `}
                            {useDeepDiveData.address?.city && `${useDeepDiveData.address?.city}, `}
                            {useDeepDiveData.address?.state && `${useDeepDiveData.address?.state}, `}
                            {useDeepDiveData.address?.country && `${useDeepDiveData.address?.country}, `}
                            {useDeepDiveData.address?.zip}
                          </div>
                        )}
                      >
                        <div className="content-child address-field-content">
                          {useDeepDiveData.address?.address_line1 && `${useDeepDiveData.address?.address_line1}, `}
                          {useDeepDiveData.address?.address_line2 && `${useDeepDiveData.address?.address_line2}, `}
                          {useDeepDiveData.address?.city && `${useDeepDiveData.address?.city}, `}
                          {useDeepDiveData.address?.state && `${useDeepDiveData.address?.state}, `}
                          {useDeepDiveData.address?.country && `${useDeepDiveData.address?.country}, `}
                          {useDeepDiveData.address?.zip}
                        </div>
                      </Tooltip>

                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  {/* <div className="emailofcontact">
                    <div className="title-child">Email:</div>
                    {useDeepDiveData.email && useDeepDiveData.email.length ? (
                      <Tooltip placement="bottom" title={useDeepDiveData.email.join(', ')}>
                        <div className="content-child">
                          <div className="content-child-title">{useDeepDiveData.email.join(', ')}</div>
                          <InputWithCopy text={useDeepDiveData} type="email" />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div> */}
                  <div className="mobileOfcontact">
                    <div className="title-child">Phone:</div>
                    {useDeepDiveData.phone_number && useDeepDiveData.phone_number.length ? (
                      <Tooltip placement="bottom" title={useDeepDiveData.phone_number.join(', ')}>
                        <div className="content-child">{useDeepDiveData.phone_number.join(', ')}</div>
                      </Tooltip>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Mobile number:</div>
                    {useDeepDiveData.mobile_number && useDeepDiveData.mobile_number.length ? (
                      <Tooltip placement="bottom" title={useDeepDiveData.mobile_number.join(', ')}>
                        <div className="content-child">{useDeepDiveData.mobile_number.join(', ')}</div>
                      </Tooltip>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">Fax Number:</div>
                    {useDeepDiveData.fax && useDeepDiveData.fax.length ? (
                      <Tooltip placement="bottom" title={useDeepDiveData.fax.join(', ')}>
                        <div className="content-child">{useDeepDiveData.fax.join(', ')}</div>
                      </Tooltip>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                  <div className="mobileOfcontact">
                    <div className="title-child">URL:</div>
                    {useDeepDiveData.url ? (
                      <Tooltip placement="bottom" title={useDeepDiveData.url}>
                        <div className="content-child">{useDeepDiveData.url}</div>
                      </Tooltip>
                    ) : (
                      <div className="content-child no-data">No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Loader>

        <Loader loading={deepDiveData.loading} error={deepDiveData.error}>
          <div className="graph-section-results">
            <div className="card">
              <div className="graph-row">
                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title">Phases vs Studies</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showPhaseModal}
                          role="presentation"
                        >
                          <Modal
                            title="Phases vs Studies"
                            visible={phaseVisible}
                            onCancel={handlePhaseCancel}
                            width={1000}
                            footer={null}
                          >
                            <div className="graph-view">
                              {showGraph(useDeepDiveData?.phase_bar_graph) ? (
                                <BarChart
                                  data={useDeepDiveData.phase_bar_graph?.slice(0, 50)}
                                  width={(useDeepDiveData.phase_bar_graph.length * 30) > 900 ? useDeepDiveData.phase_bar_graph.length * 30 : 900}
                                  height={280}
                                  top={15}
                                  bottom={90}
                                  left={30}
                                  right={0}
                                  studies="phase"
                                  checkDataset="centers"
                                  titleName="Phase"
                                  showModal
                                  setIsConfirmationModal={setIsConfirmationModal}
                                />
                              ) : (
                                <span className="no-data-inside">No data available</span>
                              )}
                            </div>
                          </Modal>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {showGraph(useDeepDiveData?.phase_bar_graph) ? (
                      <BarChart
                        data={useDeepDiveData?.phase_bar_graph?.slice(0, 4)}
                        width={350}
                        height={260}
                        top={15}
                        bottom={90}
                        left={30}
                        right={0}
                        studies="phase"
                        checkDataset="centers"
                        titleName="Phase"
                        showModal
                        setIsConfirmationModal={setIsConfirmationModal}
                      />
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title">Studies by Sponsor</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showSponsorModal}
                          role="presentation"
                        />
                      </Tooltip>

                      <Modal
                        title="Studies by Sponsor"
                        open={sponsorVisible}
                        onCancel={handleSponsorCancel}
                        width={1000}
                        footer={null}
                        destroyOnClose
                      >
                        <div className="graph-view barchart">
                          {showGraph(useDeepDiveData?.sponsor_donut_chart) ? (
                            <>
                              <BarChart
                                data={useDeepDiveData.sponsor_donut_chart}
                                width={(useDeepDiveData.sponsor_donut_chart.length * 30) > 900 ? useDeepDiveData.sponsor_donut_chart.length * 30 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="sponsor"
                                checkDataset="centers"
                                titleName="Sponsor"
                                showModal
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            </>
                          ) : (
                            <span className="no-data-inside">No data available</span>
                          )}
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {showGraph(useDeepDiveData?.sponsor_donut_chart) ? (
                      <>
                        <BarChart
                          data={useDeepDiveData?.sponsor_donut_chart?.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={100}
                          left={30}
                          right={60}
                          studies="sponsor"
                          checkDataset="centers"
                          titleName="Sponsor"
                          showModal
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      </>
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title">Indication vs Studies</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showIndicationModal}
                          role="presentation"
                        >
                          <Modal
                            title="Indication vs Studies"
                            visible={indicationVisible}
                            onCancel={handleIndicationCancel}
                            width={1000}
                            footer={null}
                          >
                            <div className="graph-view">
                              {showGraph(useDeepDiveData?.indication_bar_graph) ? (
                                <BarChart
                                  data={useDeepDiveData.indication_bar_graph.slice(0, 50)}
                                  width={(useDeepDiveData.indication_bar_graph.length * 30) > 900 ? 1500 : 900}
                                  height={400}
                                  top={15}
                                  bottom={200}
                                  left={30}
                                  right={200}
                                  studies="indication"
                                  checkDataset="centers"
                                  titleName="Indication"
                                  showModal
                                  setIsConfirmationModal={setIsConfirmationModal}
                                />
                              ) : (
                                <span className="no-data-inside">No data available</span>
                              )}
                            </div>
                          </Modal>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {showGraph(useDeepDiveData?.indication_bar_graph) ? (
                      <BarChart
                        data={useDeepDiveData.indication_bar_graph.slice(0, 4)}
                        width={350}
                        height={260}
                        top={15}
                        bottom={100}
                        left={30}
                        right={60}
                        studies="indication"
                        checkDataset="centers"
                        titleName="Indication"
                        showModal
                        setIsConfirmationModal={setIsConfirmationModal}
                      />
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title"># Studies by Age range</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showAgeRangeModal}
                          role="presentation"
                        />
                      </Tooltip>
                      <Modal
                        title="# Studies by Age range"
                        open={ageRangeVisible}
                        onCancel={handleAgeRangeCancel}
                        width={1000}
                        footer={null}
                      >
                        <div className="graph-view barchart">
                          {showGraph(useDeepDiveData?.age_range_pie_chart) ? (
                            <BarChart
                              data={useDeepDiveData?.age_range_pie_chart.map((itm) => {
                                return { ...itm, value: itm.study_count }
                              })}
                              width={(useDeepDiveData.age_range_pie_chart.length * 30) > 900 ? useDeepDiveData.age_range_pie_chart.length * 30 : 900}
                              height={400}
                              top={15}
                              bottom={200}
                              left={30}
                              right={200}
                              studies="age_range"
                              checkDataset="centers"
                              titleName="Age Range"
                              showModal
                              setIsConfirmationModal={setIsConfirmationModal}
                            />
                          ) : (
                            <span className="no-data-inside">No data available</span>
                          )}
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {showGraph(useDeepDiveData?.age_range_pie_chart) ? (
                      <BarChart
                        data={useDeepDiveData?.age_range_pie_chart.map((itm) => {
                          return { ...itm, value: itm.study_count }
                        })}
                        width={350}
                        height={260}
                        top={15}
                        bottom={90}
                        left={40}
                        right={10}
                        studies="age_range"
                        checkDataset="centers"
                        titleName="Age Range"
                        showModal
                        setIsConfirmationModal={setIsConfirmationModal}
                      />
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title">Census Data</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showPatientTrendModal}
                          role="presentation"
                        />
                      </Tooltip>
                      <Modal
                        title="Census data"
                        visible={patientTrendVisible}
                        onCancel={handlePatientTrendCancel}
                        width={1000}
                        footer={null}
                      >
                        <div className="graph-view barchart">
                          {useDeepDiveData?.census_data ? (
                            Object.keys(useDeepDiveData.census_data).length ? <Bar options={options} data={renderGraph(useDeepDiveData)} /> : <div className="no-data-inside">No data found</div>
                          ) : (
                            <span className="no-data-inside">No data available</span>
                          )}
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {useDeepDiveData?.census_data ? (
                      Object.keys(useDeepDiveData.census_data).length ? <Bar options={options} data={renderGraph(useDeepDiveData)} /> : <div className="no-data-inside">No data found</div>
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                <div className="graph-sec">
                  <div className="graph-header">
                    <div className="graph-title">Status vs Studies</div>
                    <div className="graph-meta-link">
                      <Tooltip title="Expand">
                        <div
                          className="goto-btn graph-head-icon"
                          onClick={showStatusModal}
                          role="presentation"
                        >
                          <Modal
                            title="Status vs Studies"
                            visible={statusVisible}
                            onCancel={handleStatusCancel}
                            width={1000}
                            footer={null}
                          >
                            <div className="graph-view">
                              <div className="App">
                                {showGraph(transformedData) ? (
                                  <ResponsiveContainer width="100%" height={400}>
                                    <Barchartt
                                      width={(transformedData.length * 30) > 900 ? transformedData.length * 30 : 900}
                                      height={280}
                                      data={transformedData}
                                      margin={{
                                        top: 15,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                      }}
                                    >
                                      <Legend />
                                      <XAxis dataKey="key" />
                                      <YAxis />
                                      <Tooltipp />
                                      <Barr dataKey="Pxl Studies" stackId="a" fill="#D3D800" onClick={onBarsClick} />
                                      <Barr dataKey="Industry Studies" stackId="a" fill="#4E565B" onClick={onBarsClick} />
                                    </Barchartt>
                                  </ResponsiveContainer>

                                ) : (
                                  <span className="no-data-inside">No data available</span>
                                )}
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="graph-view barchart">
                    {showGraph(transformedData) ? (
                      <Barchartt
                        width={350}
                        height={300}
                        data={transformedData}
                        margin={{
                          top: 15,
                          right: 0,
                          left: 0,
                          bottom: 90,
                        }}
                      >
                        <Legend />
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Tooltipp />
                        <Barr dataKey="Pxl Studies" stackId="a" fill="#D3D800" onClick={onBarsClick} />
                        <Barr dataKey="Industry Studies" stackId="a" fill="#4E565B" onClick={onBarsClick} />
                      </Barchartt>
                    ) : (
                      <span className="no-data-inside">No data available</span>
                    )}
                  </div>
                </div>

                {showStudiesModal
                  && (
                    <Modal
                      title={`Status : ${clickedData.key}`}
                      open={showStudiesModal}
                      onCancel={handleCancel}
                      destroyOnClose
                      footer={null}
                      className="showstudiesdata-modal"
                    >
                      <ShowStudiesData clickedData={clickedData} filterOn="status" dataset="centers" />
                    </Modal>
                  )}
              </div>
            </div>
          </div>
        </Loader>

        <div className="card center-card-ids">
          <div className="content deepdive-content">
            <div className="right-side">
              <div className="tab-title"> IDs </div>
              <div className="id-card">
                <div className="id-title">
                  CCN Number :
                </div>
                <div className="id-data">
                  {deepDiveData?.data?.ccn_number ? deepDiveData?.data?.ccn_number : '-'}
                </div>
              </div>
              <div className="id-card">
                <div className="id-title">
                  Taxation IDs:
                </div>
                <div className="id-data">
                  {deepDiveData?.data?.taxation_ids?.length ? deepDiveData?.data?.taxation_ids.join(',') : '-'}
                </div>
              </div>
              <div className="id-card">
                <div className="id-title">
                  NPI IDs :
                </div>
                <div className="id-data">
                  {deepDiveData?.data?.npi?.length ? deepDiveData?.data?.npi.join(',') : '-'}
                </div>
              </div>
              <div className="id-card">
                <div className="id-title">
                  PAC IDs :
                </div>
                <div className="id-data">
                  {deepDiveData?.data?.pac_ids?.length ? deepDiveData?.data?.pac_ids.join(',') : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isConfirmationModal}
        onOk={() => setIsConfirmationModal(false)}
        onCancel={() => setIsConfirmationModal(false)}
        title="Download Confirmation"
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        Your file will be downloaded shortly.
      </Modal>
    </div>
  )
}

export default CenterDetails;
