import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import {
  Rate, Tooltip, Dropdown, Menu, Button, Input, Modal, Popover, Checkbox,
} from 'antd';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import Loader from '../../../common/components/Loader';
import {
  getHierarchyAction,
  getHierarchyResetAction,
  getPisInHierarchyAction,
  getMorePisInHierarchyAction,
  getPisInHierarchyResetAction,
  addChildCenterInHierarchyAction,
  addChildCenterInHierarchyResetAction,
  removeChildCenterFromHierarchyAction,
  removeChildCenterFromHierarchyResetAction,
  changeParentInHierarchyAction,
  changeParentInHierarchyResetAction,
} from './logic'
import { updatePiAction, updatePiResetAction } from '../../components/AddInvestigator/logic';
import { getDocProfileValuesAction } from '../DoctorProfile/logic';
import { getTemplate } from '../SearchBar/template';
import OutsideClick from '../../../common/components/OutsideClick';
import { getAutocompleteValuesAction, getAutocompleteValuesCancelAction, getAutocompleteValuesResetAction } from '../SearchBar/logic';
import { isViewOnlyAccess } from '../../../utils';

const InvestigatorHierarchy = (props) => {
  const location = useLocation();
  const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [getHierarchyData, setGetHierarchyData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [showTypeMore, setShowTypeMore] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [showPisOfCenter, setShowPisOfCenter] = useState('');
  const [fromVal, setFromVal] = useState(1);
  const [pisTotal, setPisTotal] = useState(0);
  const [showParentCenterSearch, setShowParentCenterSearch] = useState(false);
  const [showChildCenterSearch, setShowChildCenterSearch] = useState(false);
  const [showInvestigatorSearch, setShowInvestigatorSearch] = useState(false);
  const [showInvestigatorSearchForParent, setShowInvestigatorSearchForParent] = useState(false);
  const [showRemoveCenterConfirm, setShowRemoveCenterConfirm] = useState(false);
  const [showParentChangeConfirm, setShowParentChangeConfirm] = useState(false);
  const [showInvestigatorAddConfirm, setShowInvestigatorAddConfirm] = useState(false);
  const [showChangePOModal, setShowChangePOModal] = useState(false);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [pisInCenterList, setPisInCenterList] = useState([]);
  const [childCenterForAddition, setChildCenterForAddition] = useState({});
  const [parentForChildAddition, setParentForChildAddition] = useState({});
  const [childCenterForRemoval, setChildCenterForRemoval] = useState({});
  const [parentForChildRemoval, setParentForChildRemoval] = useState({});
  const [parentCenterForChange, setParentCenterForChange] = useState({});
  const [investigatorForAddition, setInvestigatorForAddition] = useState({});
  const [centerForInvestigatorRemoval, setCenterForInvestigatorRemoval] = useState({});
  const [investigatorForPOChange, setInvestigatorForPOChange] = useState({});
  const [oldParentCenterForChange, setOldParentCenterForChange] = useState('');
  const [centerForInvestigatorAddition, setCenterForInvestigatorAddition] = useState({});
  const hierarchyData = useSelector((store) => store.hierarchyData);
  const pisInHierarchyData = useSelector((store) => store.pisInHierarchyData);
  const updateSiteResponse = useSelector((store) => store.updateSiteResponse);
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const removeChildCenterFromHierarchyResponse = useSelector((state) => state.removeChildCenterFromHierarchyResponse);
  const addChildCenterInHierarchyResponse = useSelector((state) => state.addChildCenterInHierarchyResponse);
  const changeParentInHierarchyResponse = useSelector((state) => state.changeParentInHierarchyResponse);
  const investigatorProfileData = useSelector((state) => state.docProfile);
  const updatePiResponse = useSelector((state) => state.updatePiResponse);
  const [checkedValueList, setCheckedValueList] = useState(['quality_rating', 'reliability_class', 'reliability_score', 'tier', 'enrolled_subjects', 'pxl_studies', 'industry_studies', 'alliance_type', 'alliance_member_name', 'networks', 'address', 'email', 'survey_email', 'compliance', 'pxl_center_sims_ids', 'pxl_center_cit_ids', 'landscape_id', 'is_invalid'])
  const [checkedHeaderList, setCheckedHeaderList] = useState(['Quality', 'Reliability', 'Reliability Score', 'Tier', 'Enrolled Subjects', '# PXL Studies', '# Industry Studies', 'Alliance Type', 'Alliance Member', 'Networks', 'Address', 'Email', 'Survey Email', 'Compliance', 'SIMS IDs', 'Citeline IDs', 'LandSCAPE ID', 'Invalid Flag'])

  useEffect(() => {
    return () => {
      dispatch(getHierarchyResetAction());
      dispatch(getPisInHierarchyResetAction());
    }
  }, []);

  useEffect(() => {
    if (parse(location.search).id) {
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
    }
  }, [parse(location.search).id])

  useEffect(() => {
    if (autoCompleteData.flag) {
      setAutosuggestOptions(autoCompleteData.data);
    }
  }, [JSON.stringify(autoCompleteData)]);

  useEffect(() => {
    if (updateSiteResponse.flag) {
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
    }
  }, [JSON.stringify(updateSiteResponse)]);

  useEffect(() => {
    if (hierarchyData.flag) {
      setGetHierarchyData(hierarchyData.data);

      if (showPisOfCenter && updatePiResponse.flag) {
        setFromVal(1);
        dispatch(getPisInHierarchyResetAction());
        dispatch(getPisInHierarchyAction({
          center_id: showPisOfCenter,
          from: 1,
          size: 10,
        }));
        dispatch(updatePiResetAction())
      }
    }
  }, [JSON.stringify(hierarchyData)])

  useEffect(() => {
    if (pisInHierarchyData.flag) {
      setPisInCenterList(pisInHierarchyData.data.data);
      setPisTotal(pisInHierarchyData.data.total);
    }
  }, [JSON.stringify(pisInHierarchyData)])

  useEffect(() => {
    if (addChildCenterInHierarchyResponse.flag) {
      setSearchText('');
      setIsConfirmationModalOpen(false);
      setChildCenterForAddition({});
      setParentForChildAddition({});
      setShowChildCenterSearch(false);
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
      dispatch(addChildCenterInHierarchyResetAction());
    }
  }, [JSON.stringify(addChildCenterInHierarchyResponse)])

  useEffect(() => {
    if (removeChildCenterFromHierarchyResponse.flag) {
      setShowRemoveCenterConfirm(false);
      setChildCenterForRemoval({});
      setParentForChildRemoval({});
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
      dispatch(removeChildCenterFromHierarchyResetAction());
    }
  }, [JSON.stringify(removeChildCenterFromHierarchyResponse)])

  useEffect(() => {
    if (changeParentInHierarchyResponse.flag) {
      setSearchText('');
      setShowParentCenterSearch(false);
      setShowParentChangeConfirm(false);
      setParentCenterForChange({});
      setOldParentCenterForChange('');
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
      dispatch(changeParentInHierarchyResetAction());
    }
  }, [JSON.stringify(changeParentInHierarchyResponse)])

  useEffect(() => {
    if (updatePiResponse.flag) {
      setShowChangePOModal(false);
      setCenterForInvestigatorRemoval({})
      setInvestigatorForPOChange({});
      setCenterForInvestigatorAddition({});
      setInvestigatorForAddition({});
      setShowInvestigatorAddConfirm(false);
      setSearchText('');
      setShowInvestigatorSearch(false);
      setShowInvestigatorSearchForParent(false);
      dispatch(getHierarchyAction({
        id: parse(location.search).id,
        type: props.type,
      }))
    }
  }, [JSON.stringify(updatePiResponse)])

  const onChangeInput = (e, type) => {
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      setDisplayDropdown(true);
      setShowTypeMore(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (e.target.value && e.target.value.trim().length > 2 && !emojiRegex.test(e.target.value)) {
      setShowTypeMore(false);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      dispatch(
        getAutocompleteValuesAction({
          query: e.target.value.trim(),
          dataset: type,
          filters: JSON.stringify({ parent_flag: false }),
        }),
      );
    } else {
      setDisplayDropdown(false);
      setSearchText('');
      if (centerForInvestigatorAddition && showChangePOModal) {
        setCenterForInvestigatorAddition({});
      }
    }
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const autoSuggestionItemClicked = (item) => {
    if (showChangePOModal) {
      setCenterForInvestigatorAddition(item);
      setDisplayDropdown(false);
      setSearchText(item.name);
    } else if (showInvestigatorSearch || showInvestigatorSearchForParent) {
      dispatch(getDocProfileValuesAction({ id: item.id, dataset: 'investigators' }))
      setInvestigatorForAddition(item);
      setShowInvestigatorAddConfirm(true);
    } else if (showParentCenterSearch) {
      setParentCenterForChange(item);
      setShowParentChangeConfirm(true);
    } else {
      setChildCenterForAddition(item);
      setIsConfirmationModalOpen(true)
    }
  };

  const isValueAllowed = (key) => {
    return !notAllowed.includes(key)
  }

  const renderAutosuggestOptions = () => {
    if (autoCompleteData.flag && autosuggestOptions.length) {
      return autosuggestOptions.map((itm, index) => {
        return (
          <div
            className="search-dropdown-result-list-card"
            role="presentation"
            key={index}
            onClick={() => autoSuggestionItemClicked(itm)}
          >
            {getTemplate(itm.type, itm, index)}
          </div>
        );
      });
    }
    if (autoCompleteData.flag && autosuggestOptions.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          <span>
            No matches found, click &#39;Search&#39; to initiate plain text search.
          </span>
        </div>
      );
    }
    if (showTypeMore) {
      return (
        <div className="autosuggest-no-data-msg">
          Please type at least 3 characters to show autosuggested options or initiate search.
        </div>
      );
    }
    return null;
  };

  const onHeaderScroll = () => {
    const source = document.getElementById('table-column-header1');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = source.scrollLeft;
    });
  }

  const onRowScroll = (e) => {
    const source = document.getElementById('table-column-header1');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = e.target.scrollLeft;
    });
    source.scrollLeft = e.target.scrollLeft;
  }

  const handleSiteMenuClick = (e, record) => {
    if (e.key === '1') {
      setParentForChildAddition(record);
      setSearchText('');
      setShowChildCenterSearch(true);
      setShowInvestigatorSearch(false);
      setShowInvestigatorSearchForParent(false);
      setShowParentCenterSearch(false);
    }
    if (e.key === '2') {
      if (showPisOfCenter !== record.parent_id) {
        onCenterClick(record);
      }
      setCenterForInvestigatorAddition(record);
      setSearchText('');
      setShowInvestigatorSearchForParent(true);
      setShowChildCenterSearch(false);
      setShowParentCenterSearch(false);
      setShowInvestigatorSearch(false);
    }
  }

  const siteDataMenu = (record) => (
    <Menu
      onClick={(e) => handleSiteMenuClick(e, record)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '1',
          label: 'Add child center',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
        },
        // {
        //   key: '2',
        //   label: 'Add investigator',
        //   className: 'project-details-dropdown-icon comparision-dropdown-icon',
        // },
      ]}
    />
  );

  const handlePrimaryOrgMenuClick = (e, record, rcd) => {
    if (e.key === '1') {
      setParentForChildRemoval(rcd);
      setChildCenterForRemoval(record);
      setShowRemoveCenterConfirm(true);
    }
    if (e.key === '2') {
      if (showPisOfCenter !== record.site_id) {
        onCenterClick(record);
      }
      setCenterForInvestigatorAddition(record);
      setSearchText('');
      setShowInvestigatorSearch(true);
      setShowInvestigatorSearchForParent(false);
      setShowChildCenterSearch(false);
      setShowParentCenterSearch(false);
    }
  }

  const primaryOrgDataMenu = (record, rcd) => (
    <Menu
      onClick={(e) => handlePrimaryOrgMenuClick(e, record, rcd)}
      className="project-detail-cardmenu-dropdown"
      items={[
        // (parse(location.search).id !== record.site_id) && (record.site_id !== rcd.parent_id)
        //   ? {
        //     key: '1',
        //     label: 'Remove organization from hierarchy',
        //     className: 'project-details-dropdown-icon comparision-dropdown-icon remove-org-from-hierarchy',
        //   }
        //   : null,
        {
          key: '2',
          label: 'Add investigator',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
        },
      ]}
    />
  );

  const handleRemoveCenterCancel = () => {
    setParentForChildRemoval({});
    setChildCenterForRemoval({});
    setShowRemoveCenterConfirm(false);
  }

  const onRemoveCenterClick = () => {
    dispatch(removeChildCenterFromHierarchyAction({
      params: {
        parent_id: parentForChildRemoval.parent_id,
        center_id: childCenterForRemoval.site_id,
      },
    }))
  }

  const onAddCenterClick = () => {
    dispatch(addChildCenterInHierarchyAction({
      center_id: childCenterForAddition.id,
      center_name: childCenterForAddition.name,
      parent_id: parentForChildAddition.parent_id,
      parent_name: parentForChildAddition.parent_name,
    }))
  }

  const onChangeParentClick = () => {
    dispatch(changeParentInHierarchyAction({
      center_id: props.data.data.id,
      center_name: props.data.data.name,
      parent_id_new: parentCenterForChange.id,
      parent_id_old: oldParentCenterForChange,
      parent_name_new: parentCenterForChange.name,
    }))
  }

  const onAddInvestigator = () => {
    const updateTemp = {
      primary_organization_id: {
        old: investigatorProfileData.data.primary_organization_id,
        new: centerForInvestigatorAddition.parent_id ? centerForInvestigatorAddition.parent_id : centerForInvestigatorAddition.site_id,
        flag: true,
      },
    }
    dispatch(updatePiAction({
      params: {
        pi_id: investigatorForAddition.id,
        direct_edit: true,
      },
      body: updateTemp,
    }))
  }

  const onChangePODone = () => {
    const updateTemp = {
      primary_organization_id: {
        old: centerForInvestigatorRemoval.parent_id ? centerForInvestigatorRemoval.parent_id : centerForInvestigatorRemoval.site_id,
        new: centerForInvestigatorAddition.id,
        flag: true,
      },
    }
    dispatch(updatePiAction({
      params: {
        pi_id: investigatorForPOChange.pi_id,
        direct_edit: true,
      },
      body: updateTemp,
    }))
  }

  const onCenterClick = (record) => {
    setFromVal(1);
    if (record.parent_id && showPisOfCenter !== record.parent_id) {
      setShowPisOfCenter(record.parent_id);
      dispatch(getPisInHierarchyResetAction());
      dispatch(getPisInHierarchyAction({
        center_id: record.parent_id,
        from: 1,
        size: 10,
      }));
    } else if (record.site_id && showPisOfCenter !== record.site_id) {
      setShowPisOfCenter(record.site_id);
      dispatch(getPisInHierarchyResetAction());
      dispatch(getPisInHierarchyAction({
        center_id: record.site_id,
        from: 1,
        size: 10,
      }));
    } else {
      setShowPisOfCenter('');
    }
  }

  const loadMoreRows = (record) => {
    dispatch(getMorePisInHierarchyAction({
      center_id: record.parent_id ? record.parent_id : record.site_id,
      from: fromVal + 1,
      size: 10,
    }));
    setFromVal(fromVal + 1);
  }

  const onChange = (e, checkedValues, checkedHeader) => {
    if (e.target.checked) {
      setCheckedValueList([...checkedValueList, checkedValues])
      setCheckedHeaderList([...checkedHeaderList, checkedHeader])
    } else {
      const newCheckedVal = checkedValueList.filter((itm) => itm !== checkedValues)
      const newCheckedHead = checkedHeaderList.filter((itm) => itm !== checkedHeader)
      setCheckedValueList(newCheckedVal)
      setCheckedHeaderList(newCheckedHead)
    }
  };

  const filterHeader = [
    {
      header_name: 'Quality',
      key: 'quality_rating',
    },
    {
      header_name: 'Reliability',
      key: 'reliability_class',
    },
    {
      header_name: 'Reliability Score',
      key: 'reliability_score',
    },
    {
      header_name: 'Tier',
      key: 'tier',
    },
    {
      header_name: 'Enrolled Subjects',
      key: 'enrolled_subjects',
    },
    {
      header_name: '# PXL Studies',
      key: 'pxl_studies',
    },
    {
      header_name: '# Industry Studies',
      key: 'industry_studies',
    },
    {
      header_name: 'Alliance Type',
      key: 'alliance_type',
    },
    {
      header_name: 'Alliance Member',
      key: 'alliance_member_name',
    },
    {
      header_name: 'Networks',
      key: 'networks',
    },
    {
      header_name: 'Address',
      key: 'address',
    },
    {
      header_name: 'Email',
      key: 'email',
    },
    {
      header_name: 'Survey Email',
      key: 'survey_email',
    },
    {
      header_name: 'Compliance',
      key: 'compliance',
    },
    {
      header_name: 'SIMS IDs',
      key: 'pxl_center_sims_ids',
    },
    {
      header_name: 'Citeline IDs',
      key: 'pxl_center_cit_ids',
    },
    {
      header_name: 'LandSCAPE ID',
      key: 'landscape_id',
    },
    {
      header_name: 'Invalid Flag',
      key: 'is_invalid',
    },
  ];
  const [notAllowed, setNotAllowed] = useState([])
  const [allowedHeader, setAllowedHeader] = useState([])

  const onApplyShowHideClick = () => {
    setVisible(false);
    const tempHeader = ['Quality', 'Reliability', 'Reliability Score', 'Tier', 'Enrolled Subjects', '# PXL Studies', '# Industry Studies', 'Alliance Type', 'Alliance Member', 'Networks', 'Address', 'Email', 'Survey Email', 'Compliance', 'SIMS IDs', 'Citeline IDs', 'LandSCAPE ID', 'Invalid Flag'];
    const tempValues = ['quality_rating', 'reliability_class', 'reliability_score', 'tier', 'enrolled_subjects', 'pxl_studies', 'industry_studies', 'alliance_type', 'alliance_member_name', 'networks', 'address', 'email', 'survey_email', 'compliance', 'pxl_center_sims_ids', 'pxl_center_cit_ids', 'landscape_id', 'is_invalid'];
    setNotAllowed(tempValues.filter((itm) => checkedValueList.indexOf(itm) === -1))
    setAllowedHeader(tempHeader.filter((itm) => checkedHeaderList.indexOf(itm) === -1))
  }

  const headers = ['Quality', 'Reliability', 'Reliability Score', 'Tier', 'Enrolled Subjects', '# PXL Studies', '# Industry Studies', 'Alliance Type', 'Alliance Member', 'Networks', 'Address', 'Email', 'Survey Email', 'Compliance', 'SIMS IDs', 'Citeline IDs', 'LandSCAPE ID', 'Invalid Flag']

  const renderHeader = () => {
    return (
      <div id="table-column-header1" className="table-column-sec table-column-header" onScroll={onHeaderScroll}>
        {headers.filter((itm) => !allowedHeader.includes(itm)).map((item) => <div className="filter-sel-data-value center">{item}</div>)}
      </div>
    )
  }

  const renderHirerchyData = (record, recordIndex) => {
    return (
      <div className="country-runanalysis-wrap" key={recordIndex}>
        <div
          className="country-name"
        >
          <span className="country-name-title">
            <a
              title={record.country}
              target="_blank"
              href={`/search/countryprofile?${stringify({
                country: record.country,
                type: 'COUNTRY',
              })}`}
              rel="noreferrer noopener"
            >
              {record.country}
            </a>
          </span>
        </div>
        {record.parent_institutes && record.parent_institutes.map((rcd, indexv) => (
          <div className="parent-site-wrap investigator-hierarchy-wrap-card-content" key={indexv}>
            {showParentCenterSearch
              ? (
                <div className="run-query-content-section level-header-section level-two-header-section">
                  <div className="run-query-content-card card-country-content">
                    <div
                      className="card-content card-country-content-header"
                    >
                      <div className="body-analysis-section level-two-header-section">
                        <div className="leftside-column">
                          <>
                            <div className="search-bar search-wrap hierachy-searchbar">
                              <div className="search-bar-wrap">
                                <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                                  <Search
                                    placeholder="Search for Center"
                                    allowClear
                                    value={searchText}
                                    loading={autoCompleteData.loading}
                                    enterButton={(<Button disabled={searchText.trim().length <= 2}><span className="search-icon" /></Button>)}
                                    size="large"
                                    maxLength="256"
                                    onChange={(e) => onChangeInput(e, 'centers')}
                                  />
                                </div>
                              </div>
                              <div
                                className="close-edit-search"
                                role="presentation"
                                onClick={() => {
                                  setSearchText('');
                                  setShowParentCenterSearch(false);
                                  setOldParentCenterForChange('');
                                }}
                              >
                                Cancel
                              </div>
                              <div className="search-dropdown-result-content">
                                {displayDropdown ? (
                                  <OutsideClick
                                    ignoreClickWithinClass="search-bar"
                                    onClickOutside={() => {
                                      setDisplayDropdown(false);
                                    }}
                                  >
                                    <div className="search-dropdown-result">
                                      <div className="search-dropdown-result-list">
                                        <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                                          {renderAutosuggestOptions()}
                                        </Loader>
                                      </div>
                                    </div>
                                  </OutsideClick>
                                ) : null}
                              </div>
                            </div>
                          </>
                        </div>
                        <div className="table-column">
                          <div className="table-column-sec table-column-body" />
                        </div>
                        <div className="lastside-column" />
                      </div>
                    </div>
                  </div>
                </div>
              )
              : (
                <div className="run-query-content-section level-header-section level-two-header-section">
                  <div className="run-query-content-card card-country-content">
                    <div
                      className="card-content card-country-content-header"
                    >
                      <div className="body-analysis-section level-two-header-section">
                        <div className={`leftside-column ${showPisOfCenter === rcd.parent_id ? 'dropdown-close' : 'dropdown-open'}`}>
                          {/* {((rcd.parent_id === parse(location.search).id) || rcd.parent_id !== parse(location.search).id) && props.type === 'centers'
                            ? (
                              <div
                                className="parent-edit-icon"
                                role="presentation"
                                onClick={() => {
                                  setSearchText('')
                                  setShowParentCenterSearch(true);
                                  setShowChildCenterSearch(false);
                                  setShowInvestigatorSearch(false);
                                  setShowInvestigatorSearchForParent(false);
                                  setOldParentCenterForChange(rcd.parent_id);
                                }}
                              />
                            )
                            : null} */}
                          {
                              props.type === 'centers' && props?.data?.data?.solo_center_flag === true ? (
                                <div className="card-content-title">{rcd.parent_name}</div>
                              ) : props.type === 'investigators' && props?.data?.primary_organization?.solo_center_flag === true ? (
                                <div className="card-content-title">{rcd.parent_name}</div>
                              )

                                : (
                                  <>
                                    <a
                                      className={`card-content-title ${props.type === 'centers' && parse(location.search).id === rcd.parent_id ? 'text-bold' : ''}`}
                                      title={rcd.parent_name}
                                      target="_blank"
                                      href={`/search/deepdives?${stringify({
                                        query: rcd.parent_name,
                                        id: rcd.parent_id,
                                        type: 'SITE_Parent',
                                        currentTab: 'parent_centers',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {rcd.parent_name}
                                    </a>
                                    <div
                                      className={
                       rcd?.center_quality === 'Compliance not preventing participation'
                       || rcd?.center_quality === 'Approved with Warning'
                         ? 'compliance-flag-icon preventing'
                         : rcd?.center_quality === 'Compliance preventing participation'
                           || rcd?.center_quality === 'DNU'
                           ? 'compliance-flag-icon not-preventing'
                           : rcd?.center_quality === 'Archived issue'
                             ? 'compliance-flag-icon archive-issue'
                             : 'heading'
                     }
                                    />
                                  </>

                                )
                            }
                          {props.type === 'centers'
                                      && ((props.data && props.data.data && props.data.data.parent_flag
                                        && (parse(location.search).id === rcd.parent_id))
                                        || (props.data && props.data.data && !props.data.data.parent_flag && (parse(location.search).id !== rcd.parent_id)))
                            ? (
                              <span
                                className="dropdown-arrow"
                                role="presentation"
                                onClick={() => onCenterClick(rcd)}
                              />
                            )
                            : null}
                        </div>
                        <div className="table-column">
                          <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                            {isValueAllowed('quality_rating') && (
                            <div className="filter-sel-data-value quality">
                              <Rate count={3} disabled value={rcd.quality_rating} />
                            </div>
                            )}
                            {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{rcd.reliability_class}</div>}
                            {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{rcd.reliability_score}</div>}
                            {isValueAllowed('tier') && (
                            <div className="filter-sel-data-value reliability">
                              {rcd.tier}
                            </div>
                            )}
                            {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{rcd.enrolled_count}</div>}
                            {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{rcd.pxl_studies}</div>}
                            {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{rcd.industry_studies}</div>}
                            {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{rcd.alliance_type ? rcd.alliance_type : '-'}</div>}
                            {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{rcd.alliance_member_name ? rcd.alliance_member_name : '-'}</div>}
                            {isValueAllowed('networks') && <div className="filter-sel-data-value center">{rcd.networks && rcd.networks.length ? rcd.networks.join('; ') : '-'}</div>}
                            {isValueAllowed('address') && (
                            <div className="filter-sel-data-value center">{rcd.address && rcd?.address?.address_line1 && `${rcd?.address?.address_line1}, `}
                              {rcd?.address?.address_line2 && `${rcd?.address?.address_line2}, `}
                              {rcd?.address?.city && `${rcd?.address?.city}, `}
                              {rcd?.address?.state && `${rcd?.address?.state}, `}
                              {rcd?.address?.country && `${rcd?.address?.country}, `}
                              {rcd?.address?.zip && `${rcd?.address?.zip}`}
                            </div>
                            )}
                            {isValueAllowed('email') && <div className="filter-sel-data-value center">{rcd.email && rcd.email.length ? rcd.email.join(', ') : '-'}</div>}
                            {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{rcd.survey_email && rcd.survey_email.length ? rcd.survey_email.join(', ') : '-'}</div>}
                            {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{rcd.compliance ? rcd.compliance : '-'}</div>}
                            {isValueAllowed('pxl_center_sims_ids') && <div className="filter-sel-data-value center"><div className="sims" title={rcd.pxl_center_sims_ids && rcd.pxl_center_sims_ids.length ? rcd.pxl_center_sims_ids.join(', ') : '-'}>{rcd.pxl_center_sims_ids && rcd.pxl_center_sims_ids.length ? rcd.pxl_center_sims_ids.join(', ') : '-'}</div></div>}
                            {isValueAllowed('pxl_center_cit_ids') && <div className="filter-sel-data-value center"><div className="sims" title={rcd.pxl_center_cit_ids && rcd.pxl_center_cit_ids.length ? rcd.pxl_center_cit_ids.join(', ') : '-'}>{rcd.pxl_center_cit_ids && rcd.pxl_center_cit_ids.length ? rcd.pxl_center_cit_ids.join(', ') : '-'}</div></div>}
                            {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{rcd.landscape_id ? rcd.landscape_id : '-'}</div>}
                            {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{rcd.is_invalid ? rcd.is_invalid : '-'}</div>}
                          </div>
                        </div>
                        {
                          (!isViewOnlyAccess()) && (
                            <div className="lastside-column">
                              {props.type === 'centers'
                                        && ((props.data && props.data.data && props.data.data.parent_flag
                                          && (parse(location.search).id === rcd.parent_id))
                                          || (props.data && props.data.data && !props.data.data.parent_flag && (parse(location.search).id !== rcd.parent_id)))
                                ? (
                                  <Dropdown overlay={() => siteDataMenu(rcd)} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                )
                                : null}
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {props.type === 'investigators' && rcd.pis && rcd.pis.length
                      ? (
                        <div className="pi-name-content">
                          {rcd.pis.map((piitm, idy) => {
                            return (
                              <div className="pi-content" key={idy}>
                                <div className="body-analysis-section">
                                  <div className="leftside-column">
                                    <a
                                      className={`pi-content-title ${props.type === 'investigators' && parse(location.search).id === piitm.pi_id ? 'text-bold' : ''}`}
                                      title={piitm.pi_name}
                                      target="_blank"
                                      href={`/search/investigatorprofile?${stringify({
                                        query: piitm.pi_name,
                                        id: piitm.pi_id,
                                        type: 'PI',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {piitm.pi_name}
                                    </a>
                                  </div>
                                  <div className="table-column">
                                    <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                      {isValueAllowed('quality_rating') && (
                                      <div className="filter-sel-data-value quality">
                                        <Rate count={3} disabled value={piitm.quality_rating} />
                                      </div>
                                      )}
                                      {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{piitm.reliability_class}</div>}
                                      {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{piitm.reliability_score}</div>}
                                      {isValueAllowed('tier') && (
                                      <div className="filter-sel-data-value reliability">
                                        {piitm.tier}
                                      </div>
                                      )}
                                      {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>}
                                      {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{piitm.pxl_studies}</div>}
                                      {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{piitm.industry_studies}</div>}
                                      {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{piitm.alliance_type ? piitm.alliance_type : '-'}</div>}
                                      {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{piitm.alliance_member_name ? piitm.alliance_member_name : '-'}</div>}
                                      {isValueAllowed('networks') && <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>}
                                      {isValueAllowed('address') && (
                                      <div className="filter-sel-data-value center">{piitm.address && piitm?.address?.address_line1 && `${piitm?.address?.address_line1}, `}
                                        {piitm?.address?.address_line2 && `${piitm?.address?.address_line2}, `}
                                        {piitm?.address?.city && `${piitm?.address?.city}, `}
                                        {piitm?.address?.state && `${piitm?.address?.state}, `}
                                        {piitm?.address?.country && `${piitm?.address?.country}, `}
                                        {piitm?.address?.zip && `${piitm?.address?.zip}`}
                                      </div>
                                      )}
                                      {isValueAllowed('email') && <div className="filter-sel-data-value center">{piitm.email && piitm.email.length ? piitm.email.join(', ') : '-'}</div>}
                                      {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{piitm.survey_email && piitm.survey_email.length ? piitm.survey_email.join(', ') : '-'}</div>}
                                      {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{piitm.compliance ? piitm.compliance : '-'}</div>}
                                      {isValueAllowed('pxl_author_sims_ids') && <div className="sims" title={piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}><div className="filter-sel-data-value center">{piitm.pxl_center_sims_ids && piitm.pxl_center_sims_ids.length ? piitm.pxl_center_sims_ids.join(', ') : '-'}</div></div>}
                                      {isValueAllowed('pxl_author_cit_ids') && <div className="sims" title={piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}><div className="filter-sel-data-value center">{piitm.pxl_center_cit_ids && piitm.pxl_center_cit_ids.length ? piitm.pxl_center_cit_ids.join(', ') : '-'}</div></div>}
                                      {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{piitm.landscape_id ? piitm.landscape_id : '-'}</div>}
                                      {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{piitm.is_invalid ? piitm.is_invalid : '-'}</div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )
                      : null}
                    {props.type === 'centers'
                                && ((props.data && props.data.data && props.data.data.parent_flag
                                  && (parse(location.search).id === rcd.parent_id))
                                  || (props.data && props.data.data && !props.data.data.parent_flag && (parse(location.search).id !== rcd.parent_id)))
                                  && showPisOfCenter === rcd.parent_id
                                  && showInvestigatorSearchForParent
                      ? (
                        <div className="pi-name-content">
                          <div className="pi-content">
                            <div className="body-analysis-section">
                              <div className="leftside-column">
                                <>
                                  <div className="search-bar search-wrap hierachy-searchbar">
                                    <div className="search-bar-wrap">
                                      <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                                        <Search
                                          placeholder="Search for Investigator"
                                          allowClear
                                          value={searchText}
                                          loading={autoCompleteData.loading}
                                          enterButton={(<Button disabled={searchText.trim().length <= 2}><span className="search-icon" /></Button>)}
                                          size="large"
                                          maxLength="256"
                                          onChange={(e) => onChangeInput(e, 'investigators')}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="close-edit-search"
                                      role="presentation"
                                      onClick={() => {
                                        setSearchText('');
                                        setShowInvestigatorSearchForParent(false);
                                        setCenterForInvestigatorAddition({});
                                      }}
                                    >
                                      Cancel
                                    </div>
                                    <div className="search-dropdown-result-content">
                                      {displayDropdown ? (
                                        <OutsideClick
                                          ignoreClickWithinClass="search-bar"
                                          onClickOutside={() => {
                                            setDisplayDropdown(false);
                                          }}
                                        >
                                          <div className="search-dropdown-result">
                                            <div className="search-dropdown-result-list">
                                              <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                                                {renderAutosuggestOptions()}
                                              </Loader>
                                            </div>
                                          </div>
                                        </OutsideClick>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : null}
                    {props.type === 'centers'
                                && ((props.data && props.data.data && props.data.data.parent_flag
                                  && (parse(location.search).id === rcd.parent_id))
                                  || (props.data && props.data.data && !props.data.data.parent_flag && (parse(location.search).id !== rcd.parent_id)))
                                  && showPisOfCenter === rcd.parent_id
                                  && pisInHierarchyData.flag && pisInCenterList.length
                      ? (
                        <LazyLoadDiv
                          className="card-list scrollbar"
                          id="pis-in-center-list"
                          total={pisTotal}
                          currentTotal={(pisInCenterList || []).length}
                          loadMoreRows={() => loadMoreRows(rcd)}
                          height="200px"
                        >
                          <div className="pi-name-content">
                            {pisInCenterList.map((piitm, idy) => {
                              return (
                                <div className="pi-content" key={idy}>
                                  <div className="body-analysis-section">
                                    <div className="leftside-column">
                                      <a
                                        className={`pi-content-title ${props.type === 'investigators' && parse(location.search).id === piitm.pi_id ? 'text-bold' : ''}`}
                                        title={piitm.pi_name}
                                        target="_blank"
                                        href={`/search/investigatorprofile?${stringify({
                                          query: piitm.pi_name,
                                          id: piitm.pi_id,
                                          type: 'PI',
                                        })}`}
                                        rel="noreferrer noopener"
                                      >
                                        {piitm.pi_name}
                                      </a>
                                    </div>
                                    <div className="table-column">
                                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                        {isValueAllowed('quality_rating') && (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={piitm.quality_rating} />
                                        </div>
                                        )}
                                        {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{piitm.reliability_class}</div>}
                                        {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{piitm.reliability_score}</div>}
                                        {isValueAllowed('tier') && (
                                        <div className="filter-sel-data-value reliability">
                                          {piitm.tier}
                                        </div>
                                        )}
                                        {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>}
                                        {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{piitm.pxl_studies}</div>}
                                        {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{piitm.industry_studies}</div>}
                                        {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{piitm.alliance_type ? piitm.alliance_type : '-'}</div>}
                                        {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{piitm.alliance_member_name ? piitm.alliance_member_name : '-'}</div>}
                                        {isValueAllowed('networks') && <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>}
                                        {isValueAllowed('address') && (
                                        <div className="filter-sel-data-value center">{piitm.address && piitm?.address?.address_line1 && `${piitm?.address?.address_line1}, `}
                                          {piitm?.address?.address_line2 && `${piitm?.address?.address_line2}, `}
                                          {piitm?.address?.city && `${piitm?.address?.city}, `}
                                          {piitm?.address?.state && `${piitm?.address?.state}, `}
                                          {piitm?.address?.country && `${piitm?.address?.country}, `}
                                          {piitm?.address?.zip && `${piitm?.address?.zip}`}
                                        </div>
                                        )}
                                        {isValueAllowed('email') && <div className="filter-sel-data-value center">{piitm.email && piitm.email.length ? piitm.email.join(', ') : '-'}</div>}
                                        {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{piitm.survey_email && piitm.survey_email.length ? piitm.survey_email.join(', ') : '-'}</div>}
                                        {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{piitm.compliance ? piitm.compliance : '-'}</div>}
                                        {isValueAllowed('pxl_author_sims_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}>{piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}</div></div>}
                                        {isValueAllowed('pxl_author_cit_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}>{piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}</div></div>}
                                        {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{piitm.landscape_id ? piitm.landscape_id : '-'}</div>}
                                        {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{piitm.is_invalid ? piitm.is_invalid : '-'}</div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </LazyLoadDiv>
                      )
                      : props.type === 'centers'
                                  && ((props.data && props.data.data && props.data.data.parent_flag
                                    && (parse(location.search).id === rcd.parent_id))
                                    || (props.data && props.data.data && !props.data.data.parent_flag && (parse(location.search).id !== rcd.parent_id)))
                                    && showPisOfCenter === rcd.parent_id
                                    && pisInHierarchyData.flag && !pisInCenterList.length
                        ? (
                          <div
                            className="no-data-found-msg"
                            style={{
                              height: '100px',
                              minHeight: '100px',
                              border: '1px solid #848484',
                              margin: '0 30px',
                            }}
                          >
                            No PIs are present under this parent institution in the data.
                          </div>
                        )
                        : null}
                  </div>
                </div>
              )}
            {rcd.primary_organizations && rcd.primary_organizations.length
              ? (
                <>
                  {showChildCenterSearch
                    ? (
                      <div className="run-query-content-section run-query-content-section-child">
                        <div className="run-query-content-card card-country-content">
                          <div
                            className="card-content card-country-content-header"
                          >
                            <div className="body-analysis-section">
                              <div className="leftside-column">
                                <>
                                  <div className="search-bar search-wrap hierachy-searchbar">
                                    <div className="search-bar-wrap">
                                      <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                                        <Search
                                          placeholder="Search for Center"
                                          allowClear
                                          value={searchText}
                                          loading={autoCompleteData.loading}
                                          enterButton={(<Button disabled={searchText.trim().length <= 2}><span className="search-icon" /></Button>)}
                                          size="large"
                                          maxLength="256"
                                          onChange={(e) => onChangeInput(e, 'centers')}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="close-edit-search"
                                      role="presentation"
                                      onClick={() => {
                                        setSearchText('');
                                        setShowChildCenterSearch(false);
                                        setParentForChildAddition({});
                                      }}
                                    >
                                      Cancel
                                    </div>
                                    <div className="search-dropdown-result-content">
                                      {displayDropdown ? (
                                        <OutsideClick
                                          ignoreClickWithinClass="search-bar"
                                          onClickOutside={() => {
                                            setDisplayDropdown(false);
                                          }}
                                        >
                                          <div className="search-dropdown-result">
                                            <div className="search-dropdown-result-list">
                                              <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                                                {renderAutosuggestOptions()}
                                              </Loader>
                                            </div>
                                          </div>
                                        </OutsideClick>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              </div>
                              <div className="table-column">
                                <div className="table-column-sec table-column-body" />
                              </div>
                              <div className="lastside-column" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : null}
                  {rcd.primary_organizations.map((recod, indexVal) => (
                    <div className="run-query-content-section run-query-content-section-child" key={indexVal}>
                      <div className="run-query-content-card card-country-content">
                        <div
                          className="card-content card-country-content-header"
                        >
                          <div className="body-analysis-section">
                            <div className={`leftside-column ${showPisOfCenter === recod.site_id ? 'dropdown-close' : 'dropdown-open'}`}>
                              <a
                                className={`card-content-title ${props.type === 'centers' && parse(location.search).id === recod.site_id ? 'text-bold' : ''}`}
                                title={recod.site_name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: recod.site_name,
                                  id: recod.site_id,
                                  type: 'SITE',
                                  currentTab: 'centers',

                                })}`}
                                rel="noreferrer noopener"
                              >
                                {recod.site_name}
                              </a>
                              <div
                                className={
                    recod?.center_quality === 'Compliance not preventing participation'
                    || recod?.center_quality === 'Approved with Warning'
                      ? 'compliance-flag-icon preventing'
                      : recod?.center_quality === 'Compliance preventing participation'
                        || recod?.center_quality === 'DNU'
                        ? 'compliance-flag-icon not-preventing'
                        : recod?.center_quality === 'Archived issue'
                          ? 'compliance-flag-icon archive-issue'
                          : 'heading'
                  }
                                title={recod?.center_quality_inherit_flag ? '(Inherited from parent)' : ''}
                              />
                              {props.type === 'centers'
                                ? (
                                  <span
                                    className="dropdown-arrow"
                                    role="presentation"
                                    onClick={() => onCenterClick(recod)}
                                  />
                                )
                                : null}
                            </div>
                            <div className="table-column">
                              <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                {isValueAllowed('quality_rating') && (
                                <div className="filter-sel-data-value quality">
                                  <Rate count={3} disabled value={recod.quality_rating} />
                                </div>
                                )}
                                {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{recod.reliability_class}</div>}
                                {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{recod.reliability_score}</div>}
                                {isValueAllowed('tier') && (
                                <div className="filter-sel-data-value reliability">
                                  {recod.tier}
                                </div>
                                )}
                                {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{recod.enrolled_count}</div>}
                                {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{recod.pxl_studies}</div>}
                                {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{recod.industry_studies}</div>}
                                {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{recod.alliance_type ? recod.alliance_type : '-'}</div>}
                                {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{recod.alliance_member_name ? recod.alliance_member_name : '-'}</div>}
                                {isValueAllowed('networks') && <div className="filter-sel-data-value center">{recod.networks && recod.networks.length ? recod.networks.join(', ') : '-'}</div>}
                                {isValueAllowed('address') && (
                                <div className="filter-sel-data-value center">{recod.address && recod?.address?.address_line1 && `${recod?.address?.address_line1}, `}
                                  {recod?.address?.address_line2 && `${recod?.address?.address_line2}, `}
                                  {recod?.address?.city && `${recod?.address?.city}, `}
                                  {recod?.address?.state && `${recod?.address?.state}, `}
                                  {recod?.address?.country && `${recod?.address?.country}, `}
                                  {recod?.address?.zip && `${recod?.address?.zip}`}
                                </div>
                                )}
                                {isValueAllowed('email') && <div className="filter-sel-data-value center">{recod.email && recod.email.length ? recod.email.join(', ') : '-'}</div>}
                                {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{recod.survey_email && recod.survey_email.length ? recod.survey_email.join(', ') : '-'}</div>}
                                {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{recod.compliance ? recod.compliance : '-'}</div>}
                                {isValueAllowed('pxl_center_sims_ids') && <div className="filter-sel-data-value center"><div className="sims" title={recod.pxl_center_sims_ids && recod.pxl_center_sims_ids.length ? recod.pxl_center_sims_ids.join(', ') : '-'}>{recod.pxl_center_sims_ids && recod.pxl_center_sims_ids.length ? recod.pxl_center_sims_ids.join(', ') : '-'}</div></div>}
                                {isValueAllowed('pxl_center_cit_ids') && <div className="filter-sel-data-value center"><div className="sims" title={recod.pxl_center_cit_ids && recod.pxl_center_cit_ids.length ? recod.pxl_center_cit_ids.join(', ') : '-'}>{recod.pxl_center_cit_ids && recod.pxl_center_cit_ids.length ? recod.pxl_center_cit_ids.join(', ') : '-'}</div></div>}
                                {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{recod.landscape_id ? recod.landscape_id : '-'}</div>}
                                {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{recod.is_invalid ? recod.is_invalid : '-'}</div>}
                              </div>
                            </div>
                            {
                              (!isViewOnlyAccess()) && (
                                <div className="lastside-column">
                                  {props.type === 'centers'
                                    ? (
                                      <Dropdown overlay={() => primaryOrgDataMenu(recod, rcd)} trigger={['click']}>
                                        <Button
                                          className="accordion-menu"
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Tooltip title="Kebab menu">
                                            <div className="accordion-menu-icon" />
                                          </Tooltip>
                                        </Button>
                                      </Dropdown>
                                    )
                                    : null}
                                </div>
                              )
                            }
                          </div>
                        </div>
                        {props.type === 'investigators' && recod.pis && recod.pis.length
                          ? (
                            <div className="pi-name-content">
                              {recod.pis.map((piitm, idy) => {
                                return (
                                  <div className="pi-content" key={idy}>
                                    <div className="body-analysis-section">
                                      <div className="leftside-column">
                                        <a
                                          className={`pi-content-title ${props.type === 'investigators' && parse(location.search).id === piitm.pi_id ? 'text-bold' : ''}`}
                                          title={piitm.pi_name}
                                          target="_blank"
                                          href={`/search/investigatorprofile?${stringify({
                                            query: piitm.pi_name,
                                            id: piitm.pi_id,
                                            type: 'PI',
                                          })}`}
                                          rel="noreferrer noopener"
                                        >
                                          {piitm.pi_name}
                                        </a>
                                      </div>
                                      <div className="table-column">
                                        <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                          {isValueAllowed('quality_rating') && (
                                          <div className="filter-sel-data-value quality">
                                            <Rate count={3} disabled value={piitm.quality_rating} />
                                          </div>
                                          )}
                                          {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{piitm.reliability_class}</div>}
                                          {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{piitm.reliability_score}</div>}
                                          {isValueAllowed('tier') && (
                                          <div className="filter-sel-data-value reliability">
                                            {piitm.tier}
                                          </div>
                                          )}
                                          {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>}
                                          {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{piitm.pxl_studies}</div>}
                                          {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{piitm.industry_studies}</div>}
                                          {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{piitm.alliance_type ? piitm.alliance_type : '-'}</div>}
                                          {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{piitm.alliance_member_name ? piitm.alliance_member_name : '-'}</div>}
                                          {isValueAllowed('networks') && <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>}
                                          {isValueAllowed('address') && (
                                          <div className="filter-sel-data-value center">{piitm.address && piitm?.address?.address_line1 && `${piitm?.address?.address_line1}, `}
                                            {piitm?.address?.address_line2 && `${piitm?.address?.address_line2}, `}
                                            {piitm?.address?.city && `${piitm?.address?.city}, `}
                                            {piitm?.address?.state && `${piitm?.address?.state}, `}
                                            {piitm?.address?.country && `${piitm?.address?.country}, `}
                                            {piitm?.address?.zip && `${piitm?.address?.zip}`}
                                          </div>
                                          )}
                                          {isValueAllowed('email') && <div className="filter-sel-data-value center">{piitm.email && piitm.email.length ? piitm.email.join(', ') : '-'}</div>}
                                          {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{piitm.survey_email && piitm.survey_email.length ? piitm.survey_email.join(', ') : '-'}</div>}
                                          {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{piitm.compliance ? piitm.compliance : '-'}</div>}
                                          {isValueAllowed('pxl_author_sims_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}>{piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}</div></div>}
                                          {isValueAllowed('pxl_author_cit_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}>{piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}</div></div>}
                                          {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{piitm.landscape_id ? piitm.landscape_id : '-'}</div>}
                                          {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{piitm.is_invalid ? piitm.is_invalid : '-'}</div>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                          : null}
                        {showPisOfCenter === recod.site_id && showInvestigatorSearch
                          ? (
                            <div className="pi-name-content">
                              <div className="pi-content">
                                <div className="body-analysis-section">
                                  <div className="leftside-column">
                                    <>
                                      <div className="search-bar search-wrap hierachy-searchbar">
                                        <div className="search-bar-wrap">
                                          <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                                            <Search
                                              placeholder="Search for Investigator"
                                              allowClear
                                              value={searchText}
                                              loading={autoCompleteData.loading}
                                              enterButton={(<Button disabled={searchText.trim().length <= 2}><span className="search-icon" /></Button>)}
                                              size="large"
                                              maxLength="256"
                                              onChange={(e) => onChangeInput(e, 'investigators')}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="close-edit-search"
                                          role="presentation"
                                          onClick={() => {
                                            setSearchText('');
                                            setShowInvestigatorSearch(false);
                                            setCenterForInvestigatorAddition({});
                                          }}
                                        >
                                          Cancel
                                        </div>
                                        <div className="search-dropdown-result-content">
                                          {displayDropdown ? (
                                            <OutsideClick
                                              ignoreClickWithinClass="search-bar"
                                              onClickOutside={() => {
                                                setDisplayDropdown(false);
                                              }}
                                            >
                                              <div className="search-dropdown-result">
                                                <div className="search-dropdown-result-list">
                                                  <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                                                    {renderAutosuggestOptions()}
                                                  </Loader>
                                                </div>
                                              </div>
                                            </OutsideClick>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          : null}
                        {showPisOfCenter === recod.site_id && pisInHierarchyData.flag && pisInCenterList.length
                          ? (
                            <LazyLoadDiv
                              className="card-list scrollbar"
                              id="pis-in-center-list"
                              total={pisTotal}
                              currentTotal={(pisInCenterList || []).length}
                              loadMoreRows={() => loadMoreRows(recod)}
                              height="200px"
                            >
                              <div className="pi-name-content">
                                {pisInCenterList.map((piitm, idy) => {
                                  return (
                                    <div className="pi-content" key={idy}>
                                      <div className="body-analysis-section">
                                        <div className="leftside-column">
                                          <a
                                            className={`pi-content-title ${props.type === 'investigators' && parse(location.search).id === piitm.pi_id ? 'text-bold' : ''}`}
                                            title={piitm.pi_name}
                                            target="_blank"
                                            href={`/search/investigatorprofile?${stringify({
                                              query: piitm.pi_name,
                                              id: piitm.pi_id,
                                              type: 'PI',
                                            })}`}
                                            rel="noreferrer noopener"
                                          >
                                            {piitm.pi_name}
                                          </a>
                                        </div>
                                        <div className="table-column">
                                          <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                            { isValueAllowed('quality_rating') && (
                                            <div className="filter-sel-data-value quality">
                                              <Rate count={3} disabled value={piitm.quality_rating} />
                                            </div>
                                            ) }
                                            {isValueAllowed('reliability_class') && <div className="filter-sel-data-value reliability">{piitm.reliability_class}</div>}
                                            {isValueAllowed('reliability_score') && <div className="filter-sel-data-value reliability">{piitm.reliability_score}</div>}
                                            {isValueAllowed('tier') && (
                                            <div className="filter-sel-data-value reliability">
                                              {piitm.tier}
                                            </div>
                                            )}
                                            {isValueAllowed('enrolled_subjects') && <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>}
                                            {isValueAllowed('pxl_studies') && <div className="filter-sel-data-value pxl-studies">{piitm.pxl_studies}</div>}
                                            {isValueAllowed('industry_studies') && <div className="filter-sel-data-value industry-studies">{piitm.industry_studies}</div>}
                                            {isValueAllowed('alliance_type') && <div className="filter-sel-data-value center">{piitm.alliance_type ? piitm.alliance_type : '-'}</div>}
                                            {isValueAllowed('alliance_member_name') && <div className="filter-sel-data-value center">{piitm.alliance_member_name ? piitm.alliance_member_name : '-'}</div>}
                                            {isValueAllowed('networks') && <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>}
                                            {isValueAllowed('address') && (
                                            <div className="filter-sel-data-value center">{piitm.address && piitm?.address?.address_line1 && `${piitm?.address?.address_line1}, `}
                                              {piitm?.address?.address_line2 && `${piitm?.address?.address_line2}, `}
                                              {piitm?.address?.city && `${piitm?.address?.city}, `}
                                              {piitm?.address?.state && `${piitm?.address?.state}, `}
                                              {piitm?.address?.country && `${piitm?.address?.country}, `}
                                              {piitm?.address?.zip && `${piitm?.address?.zip}`}
                                            </div>
                                            )}
                                            {isValueAllowed('email') && <div className="filter-sel-data-value center">{piitm.email && piitm.email.length ? piitm.email.join(', ') : '-'}</div>}
                                            {isValueAllowed('survey_email') && <div className="filter-sel-data-value center">{piitm.survey_email && piitm.survey_email.length ? piitm.survey_email.join(', ') : '-'}</div>}
                                            {isValueAllowed('compliance') && <div className="filter-sel-data-value center">{piitm.compliance ? piitm.compliance : '-'}</div>}
                                            {isValueAllowed('pxl_author_sims_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}>{piitm.pxl_author_sims_ids && piitm.pxl_author_sims_ids.length ? piitm.pxl_author_sims_ids.join(', ') : '-'}</div></div>}
                                            {isValueAllowed('pxl_author_cit_ids') && <div className="filter-sel-data-value center"><div className="sims" title={piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}>{piitm.pxl_author_cit_ids && piitm.pxl_author_cit_ids.length ? piitm.pxl_author_cit_ids.join(', ') : '-'}</div></div>}
                                            {isValueAllowed('landscape_id') && <div className="filter-sel-data-value center">{piitm.landscape_id ? piitm.landscape_id : '-'}</div>}
                                            {isValueAllowed('is_invalid') && <div className="filter-sel-data-value center">{piitm.is_invalid ? piitm.is_invalid : '-'}</div>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </LazyLoadDiv>
                          )
                          : showPisOfCenter === recod.site_id && pisInHierarchyData.flag && !pisInCenterList.length
                            ? (
                              <div
                                className="no-data-found-msg"
                                style={{
                                  height: '100px',
                                  minHeight: '100px',
                                  border: '1px solid #848484',
                                  margin: '0 30px',
                                }}
                              >
                                No PIs are present under this center in the data.
                              </div>
                            )
                            : null}
                      </div>
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="investigator-hierarchy-wrap">
      <Loader loading={hierarchyData.loading} error={hierarchyData.error}>
        {hierarchyData.flag && getHierarchyData.data && getHierarchyData.data.length
          ? (
            <div className="investigator-hierarchy-wrap-card card">
              <Popover
                content={(
                  <div>
                    <div className="show-hide-btns">
                      <Button onClick={onApplyShowHideClick} type="primary">
                        Apply
                      </Button>
                    </div>
                  </div>
          )}
                title={(
                  <div>
                    {filterHeader.map((itm) => {
                      return (
                        <div className="compare-row-content-data-value">
                          <Checkbox checked={checkedValueList.indexOf(itm.key) !== -1} onChange={(e) => onChange(e, itm.key, itm.header_name)}>{itm.header_name}</Checkbox>
                        </div>
                      );
                    })}
                  </div>
                  )}
                trigger="click"
                open={visible}
              >
                <div className="show-hide-btn-sec">
                  <Button type="primary" onClick={handleVisibleChange}>Show/Hide columns</Button>
                </div>
              </Popover>
              <div className="project-data-wrap-content-header-filter-value">
                <div className="header-analysis-section">
                  <div className="leftside-column" />
                  <div className="table-column">
                    {renderHeader()}
                  </div>
                  <div className="lastside-column" />
                </div>
              </div>
              {hierarchyData.flag && getHierarchyData.data && getHierarchyData.data.map((record, recordIndex) => (
                renderHirerchyData(record, recordIndex)
              ))}
            </div>
          )
          : (
            <div className="no-data-found-msg">
              No relevant data found
            </div>
          )}
      </Loader>

      <>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Remove center from hierarchy?"
          visible={showRemoveCenterConfirm}
          className="del-modal-wrapper"
          onCancel={handleRemoveCenterCancel}
          destroyOnClose
          footer={null}
        >
          <Loader loading={removeChildCenterFromHierarchyResponse.loading} error={removeChildCenterFromHierarchyResponse.error}>
            <div className="delete-modal-card">
              {`Are you sure you want to remove "${childCenterForRemoval.site_name}" from "${parentForChildRemoval.parent_name}'s" hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onRemoveCenterClick()}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Add center to hierarchy?"
          visible={isConfirmationModalOpen}
          className="del-modal-wrapper"
          onCancel={() => {
            setChildCenterForAddition({});
            setIsConfirmationModalOpen(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader loading={addChildCenterInHierarchyResponse.loading} error={addChildCenterInHierarchyResponse.error}>
            <div className="delete-modal-card">
              {`Are you sure you want to add "${childCenterForAddition.name}" to "${parentForChildAddition.parent_name}'s" hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onAddCenterClick()}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Change parent in hierarchy?"
          visible={showParentChangeConfirm}
          className="del-modal-wrapper"
          onCancel={() => {
            setParentCenterForChange({});
            setShowParentChangeConfirm(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader loading={changeParentInHierarchyResponse.loading} error={changeParentInHierarchyResponse.error}>
            <div className="delete-modal-card">
              {`Are you sure you want to change parent to "${parentCenterForChange.name}" in the hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onChangeParentClick()}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ marginTop: '81px', width: '100%' }}
          title="Add investigator in the hierarchy?"
          visible={showInvestigatorAddConfirm}
          className="del-modal-wrapper"
          onCancel={() => {
            setInvestigatorForAddition({});
            setShowInvestigatorAddConfirm(false);
          }}
          destroyOnClose
          footer={null}
        >
          <Loader loading={updatePiResponse.loading || investigatorProfileData.loading} error={updatePiResponse.error}>
            <div className="delete-modal-card">
              {`Are you sure you want to add "${investigatorForAddition.name}" to "${centerForInvestigatorAddition.parent_name ? centerForInvestigatorAddition.parent_name : centerForInvestigatorAddition.site_name}" in the hierarchy?`}
            </div>
            <div className="footer-btn">
              <Button
                className="delete-button"
                type="primary"
                onClick={() => onAddInvestigator()}
                disabled={!investigatorProfileData.flag}
              >
                Yes
              </Button>
            </div>
          </Loader>
        </Modal>
        <Modal
          style={{ width: '100%', height: 'calc(100vh - 200px)' }}
          bodyStyle={{ overflowX: 'scroll' }}
          title={(
            <div className="header-wrapper">
              <div className="modal-title">
                <span className="org-icon" />
                Current primary center
              </div>
              <div className="project-wrapper">
                {centerForInvestigatorRemoval.parent_name ? centerForInvestigatorRemoval.parent_name : centerForInvestigatorRemoval.site_name}
              </div>
            </div>
          )}
          visible={showChangePOModal}
          onCancel={() => {
            setShowChangePOModal(false);
            setInvestigatorForPOChange({});
          }}
          className="modal-wrapper change-pi-po-modal"
          destroyOnClose
          footer={null}
        >
          <Loader loading={updatePiResponse.loading} error={updatePiResponse.error}>
            <div className="modal-content">
              <>
                <div className="searchbar-add-title">Change primary center to</div>
                <div className="search-bar search-wrap hierachy-searchbar">
                  <div className="search-bar-wrap">
                    <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                      <Search
                        placeholder="Search for Center"
                        allowClear
                        value={searchText}
                        loading={autoCompleteData.loading}
                        enterButton={(<Button disabled={searchText.trim().length <= 2}><span className="search-icon" /></Button>)}
                        size="large"
                        maxLength="256"
                        onChange={(e) => onChangeInput(e, 'centers')}
                      />
                    </div>
                  </div>
                  <div className="search-dropdown-result-content">
                    {displayDropdown ? (
                      <OutsideClick
                        ignoreClickWithinClass="search-bar"
                        onClickOutside={() => {
                          setDisplayDropdown(false);
                        }}
                      >
                        <div className="search-dropdown-result">
                          <div className="search-dropdown-result-list">
                            <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                              {renderAutosuggestOptions()}
                            </Loader>
                          </div>
                        </div>
                      </OutsideClick>
                    ) : null}
                  </div>
                </div>
              </>
            </div>
            <div className="share-footer-btn">
              <Button
                className="done-button"
                type="primary"
                onClick={() => onChangePODone()}
                disabled={!centerForInvestigatorAddition.id}
              >Done
              </Button>
            </div>
          </Loader>
        </Modal>
      </>
    </div>
  )
}

export default InvestigatorHierarchy;
